import Page_LiftedBottomPageSkinComponent from '@wix/thunderbolt-elements/src/thunderbolt-core-components/Page/viewer/skinComps/LiftedBottom/LiftedBottomPageSkin.skin';


const Page_LiftedBottomPageSkin = {
  component: Page_LiftedBottomPageSkinComponent
};


export const components = {
  ['Page_LiftedBottomPageSkin']: Page_LiftedBottomPageSkin
};


// temporary export
export const version = "1.0.0"
